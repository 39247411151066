.Cursor {
  position: absolute;
  z-index: 2;
  pointer-events: none;
}

.App {
  background: #000;
  height: 100%;
  width: 100%;
}

.Content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header {
  z-index: 1;
  color: #fff;
  margin-top: -20rem;
  position: absolute;
  font-size: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px) brightness(95%);
  -webkit-backdrop-filter: blur(5px) brightness(95%);
  border-radius: 15px;
}

.About {
  font-size: 1.5rem;
  padding: 0 2rem;
  max-width: 60rem;
  min-width: 45rem;
}

.Links {
  height: 100%;
  width: 100%;
  min-width: 1500px;
  min-height: 680px;
}