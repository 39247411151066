.container {
  position: absolute;
  bottom: 70px;
  left: 50%;
  opacity: 50%;
}

.main {
  position: relative;
  display: block;
  padding: 10px;
}

.main::before {
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
  width: 24px;
  height: 40px;
  border: 2px solid white;
  border-radius: 12px;
  content: "";
}

.main::after {
  display: block;
  position: absolute;
  top: 9px;
  left: 13px;
  width: 2px;
  height: 8px;
  background: white;
  border-radius: 1px;
  content: "";
  animation-name: scroll;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
}

@keyframes scroll {
  0% {
    opacity: 1;
    height: 8px;
  }

  20% {
    opacity: 1;
    height: 8px;
  }

  30% {
    transform: translateY(0);
    opacity: 1;
    height: 19px;
  }

  40% {
    opacity: 1;
  }

  80% {
    transform: translateY(19px);
    opacity: 0;
    height: 0;
  }

  81% {
    transform: translateY(0);
    opacity: 0;
    height: 8px;
  }

  100% {
    opacity: 1;
    height: 8px;
  }
}